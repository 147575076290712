import * as React from 'react';
import LandingPage from './LandingPage';
import { ILandingPage } from '../models/data';

import firebase from "gatsby-plugin-firebase"

export default class extends React.Component<ILandingPage, {}> {
  constructor(props: ILandingPage, context: any) {
    super(props, context)
  }

  componentDidMount() {
    firebase.performance();
  }

  public render() {
    const { data } = this.props;
    return (
      <LandingPage data={data}/>
    )
  }
}

export const query = graphql`
  {
    prismicLandingpage {
      data {
        image_one {
          thumbnails
          url
          alt
        }
        image_two {
          thumbnails
          url
          alt
        }
        coming_soon {
          thumbnails
          url
          alt
        }
        logo {
          url
          alt
        }
        value_proposition {
          html
        }
        product_features {
          html
        }
        copyright {
          html
        }
        background {
          html
        }
      }
    }
  }
`